.categoryBox{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


@media only screen and (max-width: 780px) {
  .categoryBox{
    padding: 0 7%;
     display: grid  !important;
    grid-template-columns: 46% 46%;
    grid-column-gap: 8%;   
  }
}
 