.orderSuccessBox {
  width: 1000px;

  height: 600px;
  margin: 50px auto;
  background: #fefefe;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.successMessage h4 {
   text-align: center;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary_color);
}

@media only screen and (max-width: 1100px) {
  .orderSuccessBox {
    width: 700px;
  }
}
@media only screen and (max-width: 768px) {
  .orderSuccessBox {
    width: 90vw;
    margin: 50px auto;
    min-height: 300px;
  }
}
