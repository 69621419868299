.listArray {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.latestTrend {
  background-color: #ffffff;
   border-radius: 4px;
  box-sizing: border-box;
  min-height: 263px !important;
  width: 190px !important;
  margin-right: 16px;
  margin-bottom: 18px;
  overflow: hidden;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
}
.latestTrend:hover {
  /* background-color: #4a4a4a; */
}
.storeTitle {
  height: 15px;
  margin: 0 8px;
  margin-top: 8px;
  overflow: hidden;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--primary_color);
}
 
.storeImage {
  width: 100%;
  height: 190px;
}
.bottomDesc {
   display: grid;
   grid-template-columns: 10% 40% 42%;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 0 8px;
  position: relative;
}
.bottomDesc img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  /* border: solid 1px #949ca2; */
  transition: all 0.5s;
}

.storeName {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  height: 21px;
  color: #4a4a4a;
  opacity: 1;
   overflow: hidden;
}
.amountTitle {
   font-weight: bold;
   font-weight: 600;
  font-size: 12px;
  text-align: right;
  color: #121212;
  margin: auto 0;
}



@media only screen and (max-width: 780px) {
.listArray{
  justify-content: center;
}
}


@media only screen and (max-width: 500px) {
  .listArray {
    display: grid;
    grid-template-columns: 190px 190px;
    grid-column-gap: 4%;
    margin: 0;
   }
   .storeName {
    padding-left: 4px;
   }
 
}


@media only screen and (max-width: 460px) {
  .listArray {
    display: grid;
     grid-template-columns: 150px 150px !important;
    justify-content: center;
     margin: 0  ;
  }
  .latestTrend {
 
  min-height: 223px !important;
}
  
   .storeImage{
    height: 150px !important;
  }

 .latestTrend {
   width: 100% !important;
 }
 .bottomDesc {
   padding: 0 5px;
 }
.storeTitle{
  padding-left: 4px;
}
 
}
/* @media only screen and (max-width: 460px) {
  .listArray {
     grid-template-columns: 150px 150px !important;
 
  }
.latestTrend {
 
  min-height: 223px !important;
}

   .storeImage{
    height: 150px !important;
  }
 
 
 
} */
