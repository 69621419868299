.borderNone {
    border: none !important;
}
.onboardingImage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 }
.itemImage img{
    height: 480px;
    width: 480px;
}

 
  
.carouselCaption {
    position: relative;
    left: auto;
    right: auto;
    width: 500px;
    margin-top: 50px;
   }

.cardTitle{    
     padding: 10px 20px;
     margin-bottom: 2em;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.482353px;
    color: var(--primary_color);
}
