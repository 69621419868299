.wellCategory {
  height: 100px;
  width: 100px;
  margin-right : 16px;
  padding: 16px 4px;
  margin-bottom: 20px;
  background-color: #ffffff;
   border-radius: 10px;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
    border: 1px solid transparent;

}
  .wellCategory:hover {
    background-color: #dffbf4;
    border: 1px solid var(--primary_color);
  }
.wellCategory p {
  width: 92px;
  height: 20px;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
 align-items: center; 
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 10px;
  color: var(--primary_color);
 }

.wellCategory img {
  width: 32px;
  height: 32px;
 }

@media only screen and (max-width: 780px) {
  .wellCategory {
     height: 135px;
    width: 100%;
    padding: 25px 4px;
   }
 
  .wellCategory p {
    font-weight: 500;
    font-size: 15px;
    height: unset;
    line-height: 14px;
    overflow: hidden;
    font-family: Montserrat;
     display: flex;
    align-items: center;
    justify-content: center;
  }

  .wellCategory img {
    width: 46px;
    height: 46px;
  }
}
