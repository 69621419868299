.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}


.buyNowBox {
  width: 1000px;
  display: grid;
  grid-template-columns: 60% 35%;
  grid-column-gap: 5%;
  justify-content: center;
  margin: 23px auto;
}

/* No cart Item */
.noCartList {
  width: 80vw;
  min-height: 500px;
  margin: 50px auto;
  background: #fefefe;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.noCartListMessage h4 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary_color);
}

/* Cart Items */

.selectedProductBox {
  width: 100%;
  min-height: 50px;
  background: #fefefe;
  border-radius: 8px;
  padding: 35px;
}
.productBoxHeader {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary_color);
}
.cartItemBox {
  margin-top: 16px;
  width: 100%;
  border: 1px solid var(--primary_color);
  box-sizing: border-box;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 70%  30%;
  padding: 16px;
  align-items: center;
}

.stockMessage {
  font-weight: 600;
  font-size: 12px;
  color: var(--primary_color);
}
.productTitle {
  font-size: 14px;
  line-height: 17px;
  color: #121212;
  margin: 5px 0;
}
.price {
  margin-top: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4f4f4f;
}
.buttonBox{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.deleteBox button{
border: 1px solid #DADEE7;
box-sizing: border-box;
border-radius: 4px;
padding: 5px;
}

.quantityButtons {
    margin-left: 20px;
  float: left;
  width: 98px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--primary_color);
  border-radius: 4px;
  overflow: hidden;
}
.quantityButtons button {
  border: none;
  height: 32px;
  width: 32px;
  background-color: var(--primary_color);
  color: #fefefe;
}
.pickupAddress {
  width: 100%;
  background: #fefefe;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding: 16px;
  margin-top: 16px;
}
.pickupAddressHeader {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary_color);
}

.pickupAddressBox {
  margin-top: 20px;
  position: relative;
  display: grid;
  grid-template-columns: 7% 60% 33%;
}

.markerImage {
  justify-content: start;
}

.shortAddress {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
}
.formattedAddress {
  margin-top: 5px;
  min-height: 30px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #959393;
}
.directionImage {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
}

/* Shipping Method Box */
.ShippingMethodBox,
.paymentMethodBox {
  margin-top: 24px;
  width: 100%;
  background: #fefefe;
  border-radius: 8px;
  padding: 30px;
}
.shippingMethodBoxHeader,
.paymentMethodBoxHeader {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary_color);
}
.shippingMethodButtons,
.paymentMethodButtons {
  display: flex;
    align-items: center;
  margin-top: 28px;
}
.shippingMethodButtons button {
  margin-right: 10px;
}
.paymentMethodButtons button {
  margin-right: 10px;
}

/* orderSummary */
.orderSummary {
  width: 100%;
  min-height: 200px;
  background: #fefefe;
  border-radius: 8px;
  padding: 21px;
}

.orderSummaryHeader {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #121212;
}

.productShortDescription {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.productShortDescription img {
  height: 100%;
  width: 70px;
  margin-right: 5px;
}
.shortDescription{
  display: flex;
  flex-direction: column;
   justify-content: space-between;
   height: 70%;
}

.shortDescriptionTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary_color);
  margin: 0;
}
.shortDescriptionStoreName {
  font-weight: 500;
  font-size: 14px;
  color: #4f4f4f;
  margin: 0;
}
.shortDescriptionAddress img {
  height: 11px;
  width: 11px;
}

/* totalQuantity */
.totalQuantity {
  margin-top: 29px;
  background: #ffffff;
  border: 1px solid var(--primary_color);
  box-sizing: border-box;
  border-radius: 8px;
  height: 50px;
  padding: 0 16px;
  display: flex;
  align-items: center;
}

/* totalAmount */
.allTotalResults {
  margin-top: 36px;
}
.totalAmount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #4f4f4f;
}

.checkoutButton {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  padding: 14px 0;
  background: linear-gradient(0deg, var(--primary_color) 0%, var(--primary_color) 100%);
  border-radius: 48px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #fefefe;
}

/* shippingAddressForm */
.shippingAddressForm {
  width: 100%;
  margin-top: 20px;
  padding: 35px;
  background: #fefefe;
  border-radius: 8px;
  min-height: 30px;
}
.shippingAddressHeader{
    font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary_color);
  margin-bottom: 20px;
}
.addAddressButton{
   padding: 8px 20px;
  border: none;
  background: linear-gradient(
    180deg,
    var(--primary_color) 0%,
    var(--primary_color) 100%
  ) !important;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color:  white; 
 }
.shippingAddressItem{
  background-color:rgb(238, 238, 238) ;
    margin-top: 20px;
  position: relative;
  display: grid;
  grid-template-columns: 9% 80% ;
  padding: 10px;
  cursor: pointer;
}
.selectedshippingAddressItem{
  background-color:rgb(238, 238, 238) ;
    margin-top: 20px;
  position: relative;
  display: grid;
  grid-template-columns: 9% 80% ;
  padding: 10px;
  cursor: pointer;
  border: 1px solid  var(--primary_color);
}

@media only screen and (max-width: 1300px) {
  .buyNowBox {
    width: 800px;
    display: grid;
    grid-template-columns: 60% 35%;
    grid-column-gap: 5%;
    justify-content: center;
    margin: 23px auto;
  }

  .cartItemBox {
   grid-template-columns: 60% 40%;
 }

}

@media only screen and (max-width: 900px) {
  .buyNowBox {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 40px;
    justify-content: center;
    margin: 23px auto;
  }

  .cartItemBox {
   grid-template-columns: 70% 30%;
 }

}
@media only screen and (max-width: 600px) {
  .buyNowBox {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 40px;
    justify-content: center;
    margin: 23px auto;
  }

  .cartItemBox {
   grid-template-columns: 100%;
 }

 .shippingMethodButtons,
.paymentMethodButtons {
  display: flex;
     align-items: center;
  margin-top: 28px;
}
 .shippingMethodButtons button,
.paymentMethodButtons button{
  font-size: 12px !important;
    padding: 8px 15px;
      border-radius: 8px;

}
 .shippingAddressForm {
    padding: 0px;
   } 
}
@media only screen and (max-width: 400px) {
   .noCartListImage{
     width: 150px;
   }
}
