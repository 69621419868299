.banner {
  background-repeat: no-repeat;
  background-position: inherit;
  background-size: cover;
  height: 217px;
}

.mycontainer {
  width: 80%;
  margin: 0 auto;
  background-color: #fff;
  padding: 3rem;
  border-radius: 10px;
  position: relative;
  top: -51px;
}

.userAvatar {
  margin-top: 4px;
  width: 40px;
  border-radius: 50% !important;
}

.btnGreenStyle {
  padding: 8px 13px;
  border: none;
  background: linear-gradient(180deg, #2bdbc0 0%, #13b58c 100%);
  border-radius: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #ffffff;
  float: right;
}

.SortbyMenu {
  background: none !important;
  border: none;
  padding-top: 0.8rem;
  float: right;
  font-weight: bold;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}

.pageTitle {
  font-weight: bold;
  font-size: 3rem;
  top: 1rem;
}

.wishListItem {
  padding: 19px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  height: 120px;
  box-sizing: border-box;
  padding-right: 15px;
  padding-left: 15px;
}

.latestTrend {
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  box-sizing: border-box;
  min-height: 235px;
}

.latestTrend p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  padding: 10px;
  height: 50px;
}

.bottomDesc {
  margin-bottom: 10px;
}

.bottomDesc img {
  width: 15%;
  margin-left: 10px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  /* border: solid 1px #949ca2; */
  padding: 3px;
  transition: all 0.5s;
}

.bottomDesc span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4a4a4a;
  opacity: 0.5;
  position: relative;
  left: 3px;
  top: 3px;
}

.storeImage {
  margin-bottom: 15px;
  width: 100%;
}

.amountTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  float: right;
  padding: 8px;
  color: #13b58c;
}

.productFilter {
  text-align: center;
}
