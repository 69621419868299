.mycontainer{
    background-color: #fff;
    width:70%;
    margin: 10px auto;
    border-radius: 10px;
}
.button {
    font-size: 2rem;
    background: none;
    border: 1.1px solid transparent;
    color: #13B58C;
    padding-top: 25px;
}

.groupcard {
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    padding:4rem;
    text-align: center;
}

.groupAvatar {
    margin-top: 4px;
    width: 82px;
    border-radius: 50% !important;
}

 .input {
     width:100%;
     margin: 0 auto;
     border: 1px solid #13B58C;
     border-radius: 24px;
     padding: 10px;
     background: #fff;
     color: #000;
 }

 .addgroup {
     padding: 4rem;
     color: #000;
     border-radius: 10px;
 }

.btnGreenStyle {
    padding: 8px 13px;
    border: none;
    background: linear-gradient(180deg, #2BDBC0 0%, #13B58C 100%);
    border-radius: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    color: #FFFFFF;
}


.typeGroup {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    height: 120px;
    box-sizing: border-box;
    
}

.typeGroup p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.typeGroup img {
    margin-bottom: 10px;
}


.groupType{
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}