.breadCrumb {
  padding: 0px 8px 15px;
  margin-bottom: 8px;
  list-style: none;
  margin-top: 20px;
}

.breadCrumb li a {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.385882px;
  color: var(--primary_color);
  opacity: 0.7;
}
.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.mycontainer {
  background-color: #fff;
  width: 70%;
  margin: 20px auto;
  border-radius: 10px;
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--primary_color);
  margin-top: 1em;
}

.addgroup {
  padding: 2rem 4rem 3rem;
  color: #000;
  border-radius: 10px;
}

.groupcard {
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 4rem;
  text-align: center;
}

.input {
  width: 100%;
  margin: 0 auto;
  border:none;
  border-bottom: 1px solid  #E2E2E2;
   padding: 5px 5px ;
   color: #000;
  outline: none;
 }

.groupAvatar {
  margin-top: 4px;
  width: 82px;
  border-radius: 50% !important;
}
.imageAvatar {
  margin-top: 4px;
  width: 100px;
  height: 100px;
 }
.categoryBox {
  padding-right: 80px;
  padding-left: 80px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.wellCategory {
  height: 100px;
  width: 100px;
  margin-right : 16px;
  padding: 16px 4px;
  margin-bottom: 20px;
  background-color: #f6f9ff;
   border-radius: 10px;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
    border: 1px solid transparent;

}
  .wellCategory:hover {
    background-color: #dffbf4;
    border: 1px solid var(--primary_color);
  }
.wellCategory p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  color: var(--primary_color);

  margin: 0;
}

.wellCategory img {
  width: 32px;
  height: 32px;
 }

.locationImage {
  position: absolute;
  top: 0%;
  right: 0;
  margin:25px  12px;
}
.closeImage {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0%;
  right: 0;
  margin: 32px 17px;
  cursor: pointer;
}
.searchResult {
  height: 200px;
  width: 100%;
  border: 1px solid #ececec;
  border-radius: 10px;
  padding: 10px 20px;
  background: #fff;
  position: absolute;
  top: 60px;
  overflow-y: scroll;
}
.addressList {
  display: flex;
  align-items: center !important;
  padding: 10px;
  margin: auto 0;
  cursor: pointer;
  border-radius: 25px;
}
.addressList:hover {
  background-color: rgba(197, 197, 197, 0.397);
}

.addressList p {
  margin: auto 10px;
}
.listLocationImage {
  height: 30px;
  width: 30px;
  padding: 5px;
  border-radius: 100%;
  background-color: #ececec;
}



@media only screen and (max-width: 1300px) {
  .mycontainer {
    width: 90%;
  }
}
@media only screen and (max-width: 700px) {
  .mycontainer {
    width: 100%;
  }
  
}
@media only screen and (max-width: 500px) {
  .categoryBox {
    padding: 0;
    display: grid  !important;
    grid-template-columns: 46% 46%;
    grid-column-gap: 8%;
    }
  .wellCategory {
     height: 135px;
    width: 100%;
    padding: 25px 4px;
   }
 
  .wellCategory p {
    font-weight: 500;
    font-size: 15px;
    height: unset;
    line-height: 14px;
    overflow: hidden;
    font-family: Montserrat;
    color: var(--primary_color);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wellCategory img {
    width: 46px;
    height: 46px;
  }
}
