.groupSuccess {
    
    display: grid;
    justify-items: center;
    text-align: center;
    margin-top: 30px;
    background: #fefefe;
    box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
    padding: 20px;
}

.pageTitel {
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
}

.pageNote {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}

.btnGreenStyle {
    padding: 8px 13px;
    border: none;
    background: linear-gradient(180deg, var(--primary_color) 0%, var(--primary_color) 100%);
    border-radius: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    color: #FFFFFF;
    float: left;
}

@media only screen and (max-width: 768px) {

    .pageTitel {
    font-weight: bold;
    font-size: 20px;
    line-height: 44px;
}
}