.categoryMenu {
  background: none !important;
  border: none;
  padding-top: 0.8rem;
  float: left;
  font-weight: bold;
}

.btnGreenStyle {
  padding: 8px 13px;
  border: none;
  background: linear-gradient(180deg, #2bdbc0 0%, #13b58c 100%);
  border-radius: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  float: left;
}

.latestTrend {
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  box-sizing: border-box;
  min-height: 235px;
}

.latestTrend p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  padding: 10px;
  height: 50px;
}

.bottomDesc {
  margin-bottom: 10px;
  padding: 10px;
  padding-bottom: 19px;
}

.bottomDesc img {
  width: 15%;
  margin-left: 10px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  padding: 3px;
  transition: all 0.5s;
}

.bottomDesc span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4a4a4a;
  opacity: 0.5;
  position: relative;
  left: 3px;
  top: 3px;
}

.pageTitle {
  font-weight: bold;
  font-size: 3rem;
  top: -0.9rem;
}

.groupImage {
  margin-bottom: 15px;
  width: 100%;
}

.viewBtn {
  float: right;
}

.popularGroup {
  display: flex;
  align-items: center;
}

.joinGroup {
  float: right;
}

.groupTitel {
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

.memberNumber {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
}
