.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.title {
  width: 100%;
   font-style: normal;
  font-weight: 500;
  font-size: 36px;
    text-align: center;
 }
 .subTitle{
   text-align: center;
 }
 

.titleAccount {
  height: 22px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.385882px;
  text-align: center;
}

.input {
  width: 100%;
  border: 1.1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 10px;
  background: none;
  color: white;
  outline: none;
}

input::placeholder {
  color: white;
}

.button {
  background: #ffffff;
  width: 100%;
  border: 1.1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 10px;
  color: var(--primary_color);
  font-size: 16px;
  font-weight: 600;
}

.inputGroup {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  border-radius: 4px;
  width: 11%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
  border-right: 0;
}

@media  only screen and (max-width: 700px) {
  .title{
    font-size: 30px;
   font-style: normal;
  font-weight: 500;
    margin: 0;
   }
  .titleAccount{
     font-size: 20px;
  line-height: 24px;
  }
}