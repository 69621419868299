.wishListsBox{
    padding-left: 43px;
    padding-right:38px ;
}
.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.SortbyMenu {
  background: none !important;
  border: none;
  padding-top: 0.8rem;
  float: left;
  font-weight: bold;
}

.pageTitle {
  font-weight: bold;
  font-size: 3rem;
  top: -0.9rem;
  margin-bottom: 17px;
}

.wishList {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.latestTrend {
  background-color: #ffffff;
   border-radius: 4px;
  box-sizing: border-box;
  min-height: 260px;
  width: 190px;
  margin-right: 16px;
  margin-bottom: 16px;
  overflow: hidden;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
}
.latestTrend:hover {
  /* background-color: #4a4a4a; */
}
.storeTitle {
  width: 180px;
  height: 17px;
  margin: 0 auto;
  margin-top: 8px;
  overflow: hidden;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary_color);
}

.latestTrend p {
  /* padding: 2px 8px; */
  /* font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--primary_color); */
}
.storeImage {
  width: 100%;
  height: 190px;
}
.bottomDesc {
   display: grid;
   grid-template-columns: 10% 40% 42%;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 0 10px;
  position: relative;
}
.bottomDesc img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  /* border: solid 1px #949ca2; */
  transition: all 0.5s;
}

.storeName {
  margin: 0;
  width: 100%;
  height: 21px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #4a4a4a;
  opacity: 1;
  overflow: hidden;
}
.amountTitle {
   font-weight: bold;
   font-weight: 600;
  font-size: 12px;
  text-align: right;
  color: #4a4a4a ;
  margin: auto 0;
}
 


@media only screen and (max-width: 780px) {
  .wishListsBox{
      padding-left: 4%;
    padding-right:4% ;
  }
.wishList{
  justify-content: center;
}
}
@media only screen and (max-width: 500px) {
  .wishList {
    display: grid;
    grid-template-columns: 190px 190px;
    grid-column-gap: 4%;
     margin: 0;
  }
 .pageTitle {
  font-weight: bold;
  font-size: 2rem;
  top: -0.9rem;
  margin-bottom: 17px;
}

 
}
@media only screen and (max-width: 490px) {
  .wishList {
    display: grid;
    grid-template-columns: 190px 190px;
    justify-content: center;
     margin: 0  ;
  }
 .latestTrend {
   width: 100%;
 }
 .bottomDesc {
   padding: 0 10px;
 }
 .storeTitle{
   padding-left: 4px;
 }
 .storeName{
   padding-left: 4px;
 }

 
}

@media only screen and (max-width: 420px) {
  .wishList {
    display: grid;
    grid-template-columns: 160px 160px;
    justify-content: center;
     margin: 0  ;
  }
  .latestTrend {
   width: 100%;
   min-height: 230px;
 }
.storeImage {
  width: 100%;
  height: 160px;
}
}

@media only screen and (max-width: 350px) {
  .wishList {
    display: grid;
    grid-template-columns: 140px 140px;
    }
  .latestTrend {
   width: 100%;
   min-height: 210px;
 }
.storeImage {
  width: 100%;
  height: 140px;
}
}
