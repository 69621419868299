.filterBox {
  margin: 30px;
  display: none;
}
.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.allStoresBox{
  padding-bottom: 20px;
}

.storesStyle {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
}

.wellStore {
  width: 185px !important;
  height: 260px;
  margin-right: 16px;
  padding-top: 31px;
  padding-bottom: 20px;
  padding-left: 17px;
  padding-right: 17px;
  margin-bottom: 16px;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none !important;
}

.wellStoreDetails {
  font-style: normal;
}

.wellStoreDetails p {
  font-size: 15px;
  line-height: 22px;
  color: #4a4a4a;
  height: 15px;
}

.imageDiv img {
  width: 64px;
  height: 64px;
  border-radius: 100%;
}

.btnGreenFollow {
  padding: 8px 13px;
  border: none;
  background: linear-gradient(180deg, var(--primary_color) 0%, var(--primary_color) 100%);
  border-radius: 24px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #ffffff !important;
  text-decoration: none !important;
}
.btnGreenUnFollowing {
  padding: 8px 13px;
  border: 1px solid var(--primary_color);
  background: white;
  border-radius: 24px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: var(--primary_color) !important;
  text-decoration: none !important;
}

.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

 

@media only screen and (max-width: 430px) {
  
  .wellStore {
    width: 175px!important;
    margin: 4%  5px;
  }
}
@media only screen and (max-width: 390px) {
  
  .wellStore {
    width: 165px!important;
    margin: 4%  5px;
  }
}
@media only screen and (max-width: 370px) {
  
  .wellStore {
    width: 155px!important;
    margin: 4%  5px;
   }
}
