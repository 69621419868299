.breadCrumb {
  padding: 0px 8px 15px;
  margin-bottom: 8px;
  list-style: none;
}
.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.breadCrumb li a {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.385882px;
  color: var(--primary_color);
  opacity: 0.7;
}

.noStore {
  font-weight: 500;
  font-size: 36px;
  text-align: center;
  letter-spacing: -0.385882px;
  margin: 4em 0;
}

.noStore img {
  margin-top: 3em;
}

 
.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--primary_color);
  margin-top: 1em;
}

.storeBanner{
 width: 100%;
 height: 217px;
 overflow: hidden;
background-image: url(../../../assets/images/store/store.svg);
  background-repeat: no-repeat;
  background-position: inherit;
  background-size: cover;
 }

  .storeHeader{
   padding-left: 34px;
   padding-right: 34px;
 }
 
 
 
.bannerText {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0523656);
  border-radius: 10px;
  padding: 0px 0px;
  padding-left: 46px;
  padding-right: 75px;
  margin-top: -77px;
  display: flex;
  justify-content: space-between;
  align-items: center;
height: 126px;
  overflow: hidden;

 }

.fashionStore{
   display: flex;
   flex-direction: row;
   align-items: center;
   
 }
 .fashionStoreNameBox{
   width: 100%;
   height: 100%;
   margin: auto 0;
   margin-left: 17px;
   display: flex;
   flex-direction: column;
   justify-content:center;
   align-items:flex-start;
 }
.fashionStore h3 {
  margin: 0;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 18px;
   letter-spacing: -0.385882px;
  color: #000000;

}

.fashionStore img {
   padding-right: 20px;
  width:70px;
  height:70px;
  border-radius: 50%;
  padding: 10px;
}

.fashionStore p {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4a4a4a;
  opacity: 0.5;
}
 .noListingBox{
   margin: 64px auto;
   width: 80%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
text-align: center;
 }

 .noListingBox h3{
   color: black;
   font: 500;
   font-size: 36px;
   margin: 15px 0;
 }
 .noListingBox button{
   color: white;
   margin: 25px 0;
 }
 .noListingBox img{
   width: 30%;
 }

.myStoreProducts{
  margin-top: 90px;
   padding-left: 43px;
  padding-right:38px ;
  display: flex;
  flex-direction: column;
}

 .find {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding: 10px;
  background-color: #f6f9ff;
}

 
.latestTrend {
  background-color: #ffffff;
   border-radius: 4px;
  box-sizing: border-box;
  min-height: 260px !important;
  width: 190px !important;
  margin-right: 16px;
  margin-bottom: 18px;
  overflow: hidden;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
}
.latestTrend:hover {
  /* background-color: #4a4a4a; */
}
.storeTitle {
  height: 15px;
  margin: 0 8px;
  margin-top: 8px;
  overflow: hidden;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--primary_color);
}

.productImage {
  width: 100%;
  height: 190px;
}
.bottomDesc {
 display: grid;
   grid-template-columns: 10% 40% 42%;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 0 8px;
  position: relative;
}
.bottomDesc img {
 width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  /* border: solid 1px #949ca2; */
  transition: all 0.5s;
}

.bottomDesc span {
 margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  height: 21px;
  color: #4a4a4a;
  opacity: 1;
   overflow: hidden;
 
}
.amountTitle {
font-weight: bold;
   font-weight: 600;
  font-size: 12px;
  text-align: right;
  color: #121212;
  margin: auto 0;
}
 .followBtn{
    display: flex;
    min-height: 80px;
   flex-direction: column;   
   justify-content: space-between;
   align-items: center;
 }
 
 .produtDontActive{
   position: absolute;
    z-index: 100;
   background: rgb(253, 186, 2);
   top: 0;
   padding: 4px;
   font-size: 12px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    border-bottom-right-radius: 10px;
 }

@media only screen and (max-width: 768px) {
 
  .myStoreProducts{
    margin-top: 30px;
  }
  .storeHeader{
   padding-left:4%;
   padding-right: 4%;
 }
 
  .fashionStore img {
  padding-right: 20px;
  width:70px;
  height:70px;
  border-radius: 50%;
  padding: 10px;
}

 .followBtn{
    display: flex;
   flex-direction: column;   
   justify-content: space-between;
   align-items: center;
 }
 .followBtn a{
  }
 .myStoreProducts{
    padding-left: 4%;
  padding-right:4%;
 
}


.find{
  justify-content: center;
    padding-left: 4%;
  padding-right:4% ;
}
 
 
}


@media only screen and (max-width: 560px) {
     .bannerText {
   border-radius: 10px;
   padding: 10px 20px;
   margin-top: -70px;
   display: block;
  align-items: center;
 }
   .find {
         display: grid;
    grid-template-columns: 190px 190px;
    grid-column-gap: 4%;
    margin: 0;
  }
 
.bottomDesc span  {
  padding-left: 4px;
}
.followBtn{
  min-height: 40px;
    display: flex;
   flex-direction: row;   
   justify-content: space-between;
   align-items: center;
 }

}


@media only screen and (max-width: 460px) {

  .storeBanner{
 width: 100%;
 height: 117px;}
  .find {
    display: grid;
     grid-template-columns: 150px 150px !important;
      margin: 0  ;
  }

 .latestTrend {
   width: 100% !important;
 }
 .bottomDesc {
   padding: 0 10px;
 }
.storeTitle{
  padding-left: 4px;
}
.latestTrend {
 
  min-height: 223px !important;
}

   .productImage{
    height: 150px !important;
  }
 
 
}
