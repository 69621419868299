.Modal {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 70%;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 15%;
  top: 25%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

@media only screen and (max-width: 768px) {
  .Modal {
    width: 80%;
    top: 20vh;
    left: 10%;
  }
}
