.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.find {
  padding-top: 20px;
   padding-left: 43px;
  padding-right:38px ;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
   background-color: #f6f9ff;
}

.cartBtn {
  width: 100px;
  border-radius: 10px;
  padding: 10px;
  margin-top: 100px;
  color: black;
  font-size: 20px;
  text-decoration: none;
}

.headingTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin: 0;
  float: left;
}
.latestTrend {
  background-color: #ffffff;
   border-radius: 4px;
  box-sizing: border-box;
  min-height: 263px !important;
  width: 190px !important;
  margin-right: 16px;
  margin-bottom: 18px;
  overflow: hidden;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
}
 
.storeTitle {
  height: 15px;
  margin: 0 8px;
  margin-top: 8px;
  overflow: hidden;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--primary_color);
}

 
.storeImage {
  width: 100%;
  height: 190px;
}
.bottomDesc {
   display: grid;
   grid-template-columns: 10% 40% 42%;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 0 8px;
  position: relative;
}
.bottomDesc img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  /* border: solid 1px #949ca2; */
  transition: all 0.5s;
}

.bottomDesc span {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  height: 21px;
  color: #4a4a4a;
  opacity: 1;
   overflow: hidden;
}
.amountTitle {
     font-weight: bold;
   font-weight: 600;
  font-size: 12px;
  text-align: right;
  color: #121212;
  margin: auto 0;
}



@media only screen and (max-width: 780px) {
.find{
  justify-content: center;
    padding-left: 4%;
  padding-right:4% ;
}
}

@media only screen and (max-width: 500px) {
  .find {
         display: grid;
    grid-template-columns: 190px 190px;
    grid-column-gap: 4%;
    margin: 0;
  }
 
.bottomDesc span  {
  padding-left: 4px;
}
 
}

@media only screen and (max-width: 460px) {
  .find {
    display: grid;
     grid-template-columns: 150px 150px !important;
      margin: 0  ;
  }
 .latestTrend {
   width: 100% !important;
 }
 .bottomDesc {
   padding: 0 10px;
 }
.storeTitle{
  padding-left: 4px;
}
.latestTrend {
 
  min-height: 223px !important;
}

   .storeImage{
    height: 150px !important;
  }
 
 
}
