.button {
    font-size: 2rem;
    background: none;
    border: 1.1px solid transparent;
    color: #13B58C;
    padding-top: 25px;
}

.productreview{
width: 508px;
height: 133px;
left: 279px;
top: 182px;
float: left;
background: #FFFFFF;
border: 1px solid #13B58C;
box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
border-radius: 8px

 }

/* .rate{

}  */

.productImg {
    width: 150px;
    height: 150px;
    left: 819px;
    top: 266px;
    margin-left: -26px;
    float:left;
}

.transactionDetails {
    position: relative;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    padding-top: 10px;
    width: 255px;
}


.rateContainer{
    border: 1px solid #fff;
    background-color: #fff;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #222222;
        width: 540px;
        height: 783px;
        left: 817px;
        top: 182px;
        padding-top: 50px;
        background: #FFFFFF;
        box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
}

.rating{
    left: 0%;
    right: 0%;
    top: 2.78%;
    bottom: 2.78%;
    padding-top: 10px;
}

.btnGreenStyle {
    padding: 8px 13px;
    border: none;
    background: linear-gradient(180deg, #2BDBC0 0%, #13B58C 100%);
    border-radius: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    width: 155px;
    height: 36px;
    left: 926px;
    top: 399px;
    border: 1px solid #A0A0A0;
    box-sizing: border-box;
    border-radius: 24px;    
    margin: 10px;
}

.input {
width: 343px;
height: 107px;
left: 916px;
top: 518px;
border: 1px solid #A0A0A0;
box-sizing: border-box;
filter: drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.05));
border-radius: 4px;
width: 314px;
left: 930px;
top: 530px;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 150%;
letter-spacing: -0.15px;
color: #9B9B9B;
margin: 0 auto;
}

.addPhoto{
    position: relative;
    left: 125px;
    float: left;
    padding-top:30px;
    padding-bottom: 30px;
}

.btnGreen {
    padding: 8px 13px;
    border: none;
    background: linear-gradient(180deg, #2BDBC0 0%, #13B58C 100%);
    border-radius: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    width: 343px;
    height: 48px;
    left: 916px;
    top: 840px;
    border: 1px solid #A0A0A0;
    box-sizing: border-box;
    border-radius: 24px;
    margin: 10px;
    
}

.titleProduct{
    padding-top: 20px;
}

.reviewBtn{
    padding-bottom: 20px;
}

.productPrice{
    float: right;
        margin: -30px;
        width: 101.9px;
        height: 22px;
        left: 682px;
        top: 453px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: linear-gradient(0deg, #2ADABF 0%, #14B78E 100%)!important;
}

.productreview {
    float: left;
}

.centerd{
    text-align: center;
}

.reviewProduct {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FF3E3E;
    border: none;
    background-color: #fff;
}

.cancelReview{
    font-size: 14px;
    line-height: 8px;
    text-align: center;
    color: #222222;
    opacity: 0.5;
    border: 1px dashed #FFFFFF;
}