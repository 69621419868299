.button {
    font-size: 2rem;
    background: none;
    border: 1.1px solid transparent;
    color: #13B58C;
}

.editProfile{
    background-color: #fff;
    width:80%;
    margin: 10px auto;
    border-radius: 10px;
    padding-bottom: 20px;
}

.profilecard {
    width:70%;
    margin: 10px auto;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    padding:4rem;
    display: flex;
    justify-content: center;
    text-align: center;
}

.userAvatar {
    margin-top: 4px;
    width: 50%;
    border-radius: 50% !important;
}


 .input {
     width:100%;
     margin: 0 auto;
     border: 1px solid #13B58C;
     border-radius: 24px;
     padding: 10px;
     background: #fff;
     color: #000;
 }

 .changepassword {
     width:70%;
     margin: 10px auto;
     padding:4rem;
     color:#000;
     border-radius: 10px;
 }

 .changeinfo {
     width:70%;
     margin: 10px auto;
     padding:4rem;
     color:#000;
     border-radius: 10px;
 }


.btnGreenStyle {
    padding: 8px 13px;
    border: none;
    background: linear-gradient(180deg, #2BDBC0 0%, #13B58C 100%);
    border-radius: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    color: #FFFFFF;
    float: right;
}