.BeforeAuth {
   display: grid;
   width: 100%;
   min-height: 100vh;
     grid-template-columns: 50% 50%;
    align-items: center;
}

 .leftDiv {
    margin: auto 0;
    display: flex;
     flex-direction: column;
     justify-content: center;
 }

.rightDiv {
   height: 100%;
    color: white;
     padding: 10% 15%;
     background: linear-gradient(180deg, var(--primary_color) 0%, var(--primary_color) 100%);
     display: flex;
     flex-direction: column;
     justify-content: center;
  }

@media only screen and (max-width: 800px) {
   .BeforeAuth {
   display: grid;
   width: 100%;
   height:500px;
   grid-template-columns: 100%;
  
}
.leftDiv{
   display: none;
}
.rightDiv{
  padding: 5%;
}
}
