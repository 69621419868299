.title {
  width: 100%;
  height: 88px;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.578824px;
}
  .Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.titleAccount {
  height: 22px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.385882px;
}

.input {
  width: 100%;
  border: 1.1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 10px;
  background: none;
  color: white;
}

/* input::placeholder {
  color: white;
} */

.button {
  width: 100%;
  border: 1.1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 10px;
  color: var(--primary_color);
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}

.PhoneDigit {
    width: 70%;
  background: none;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  outline: none;
}

/* .PhoneDigit:focus {
  color: white;
} */

.col2 {
  float: left;
  width: 16%;
  padding: 12px 30px;
}
