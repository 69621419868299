

.noProduct {
    font-weight: 500;
    font-size: 36px;
    text-align: center;
    letter-spacing: -0.385882px;

}

.banner {
    background-repeat: no-repeat;
    background-position: inherit;
    background-size: cover;
    height: 217px;
}

.mycontainer {
    width: 80%;
    margin: 0 auto;
    background-color: #fff;
    padding: 3rem;
    border-radius: 10px;
    position: relative;
    top: -51px;
}

.userAvatar {
    margin-top: 4px;
    width: 40px;
    border-radius: 50% !important;
}

.btnGreenStyle {
    padding: 8px 13px;
    border: none;
    background: linear-gradient(180deg, #2BDBC0 0%, #13B58C 100%);
    border-radius: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    color: #FFFFFF;
    float: right;
}

